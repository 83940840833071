<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title statement-head">
            Statement Report
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs2>
                <v-text-field
                  v-model="form.enroll_code"
                  class="pa-0"
                  label="Enroll Code"
                  placeholder="ST-76001"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4>
                <v-autocomplete
                  :disabled="form.enroll_code.length > 0 && students.length < 1"
                  :search-input.sync="search"
                  :items="students"
                  hide-no-data
                  :loading="studentLoading"
                  class="pa-0"
                  label="Search Students"
                  v-model="form.name"
                  :hint="!students.length ? 'Type atleast 3 characters' : ''"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2>
                <search-button
                  style=""
                  :disabled="!form.enroll_code"
                  permission="billing-report-statement"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
              <v-flex xs3>
                <print-buttons
                  v-if="form.items.data.length"
                  :downloadUrl="downloadUrl"
                  :passAuthToReport="true"
                  style="float: right"
                  :excel="false"
                  labelExcel="Download Excel"
                  labelPdf="Download Pdf"
                ></print-buttons>
              </v-flex>
              <v-flex xs1>
                <transition
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-if="students.length || form.enroll_codelength"
                    fab
                    small
                    @click="
                      (form.enroll_code = ''), (students = []), (form.name = '')
                    "
                    style="z-index: 1;"
                    class="error"
                  >
                    <v-icon dark>close</v-icon>
                  </v-btn>
                </transition>
              </v-flex>
            </v-card-title>
          </v-card>
          <div
            style="margin-left: 20px; margin-top: 5px; color: #ff5252"
            v-if="advanceOpening > 0 && form.items.data.length"
          >
            <span>Opening Advance Amount : </span>
            <span>{{ advanceOpening.currency() }}</span>
          </div>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :server-items-length="form.items.meta.total"
            :loading="loading"
            :options.sync="pagination"
            hide-default-footer
          >
            <template v-slot:item="{ index, item }">
              <tr class="">
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.bill_date }}</td>
                <td class="text-xs-left">
                  {{ item.via.toUpperCase() }}
                  <div v-if="item.bill_month">
                    <small
                      >({{
                        item.bill_month === "13"
                          ? "Baisakh (13)"
                          : item.bill_month === "14"
                          ? "Jestha (14)"
                          : item.bill_month
                      }})</small
                    >
                  </div>
                </td>
                <td class="text-xs-left">
                  {{
                    item.bill_amount != 0 ? item.bill_amount.currency() : "-"
                  }}
                </td>
                <td class="text-xs-left">
                  {{
                    item.paid_amount != 0 ? item.paid_amount.currency() : "-"
                  }}
                </td>
                <td class="text-xs-left">
                  {{
                    item.instant_discount != 0
                      ? item.instant_discount.currency()
                      : "-"
                  }}
                </td>
                <td>{{ item.remarks }}</td>
              </tr>
              <tr
                class="info"
                style="color: white; font-weight: bold;"
                v-if="index === form.items.data.length - 1"
              >
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{{ billSum.currency() }}</td>
                <td>{{ totalSum.currency() }}</td>
                <td>{{ instantDiscountSum.currency() }}</td>
                <td></td>
              </tr>
              <tr
                class="error"
                style="color: white; font-weight: bold;"
                v-if="index === form.items.data.length - 1"
              >
                <td :colspan="headers.length">
                  <span v-if="totalDueAdvAmount > 0">
                    Remaining Due = {{ totalDueAdvAmount.currency() }} (
                    {{ convertNumberToWords(totalDueAdvAmount, true) }} )
                  </span>
                  <span v-else>
                    <span v-if="Math.abs(totalDueAdvAmount) != 0">
                      Advance = {{ Math.abs(totalDueAdvAmount).currency() }} (
                      {{
                        convertNumberToWords(Math.abs(totalDueAdvAmount), true)
                      }}
                      )
                    </span>
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { convertNumberToWords, formatNumber } from "@/library/helpers";
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";

export default {
  mixins: [Mixins],

  data: () => ({
    form: new Form(
      {
        enroll_code: "",
        name: "",
      },
      "/api/report/statement"
    ),
    pagination: {
      itemsPerPage: 100,
    },
    loading: false,
    totalSum: 0,
    billSum: 0,
    instantDiscountSum: 0,
    search: null,
    downloadUrl: "",
    students: [],
    studentLoading: false,
    advanceOpening: 0,
    totalDueAdvAmount: 0,
    headers: [
      { text: "S. No", align: "left", value: "id", sortable: false },
      { text: "Date", align: "left", value: "bill_date", sortable: false },
      { text: "Via", align: "left", value: "via", sortable: false },
      {
        text: "Bill Amount",
        align: "left",
        value: "bill_amount",
        sortable: false,
        width: 140,
      },
      {
        text: "Paid Amount",
        align: "left",
        value: "paid_amount",
        sortable: false,
        width: 140,
      },
      {
        text: "Instant Discount",
        align: "left",
        value: "instant_discount",
        sortable: false,
        width: 140,
      },
      {
        text: "Remarks",
        align: "left",
        value: "remarks",
        sortable: false,
        width: 200,
      },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.get();
  },
  watch: {
    "form.enroll_code": function(v) {
      this.form.items.data = [];
      this.form.name = "";
      if (v && v.length > 7) {
        // this.get();
      }
    },
    search(val) {
      if (!val) {
        this.students = [];
        this.studentLoading = false;
        return;
      }
      if (this.isLoading) return;
      if (val && val.length > 2 && this.students.length < 1) {
        this.studentLoading = true;
        this.$rest
          .get("api/search-student-grade-section?search=" + val)
          .then(({ data }) => {
            this.students = data.data.map((item) => {
              return {
                value: item.enroll_code,
                text:
                  item.name +
                  " (" +
                  item.grade +
                  "-" +
                  item.section +
                  ") - " +
                  item.roll,
                enrollCode: item.enroll_code,
              };
            });
          })
          .catch((err) => {})
          .finally(() => (this.studentLoading = false));
      }
    },
    "form.name": function(value) {
      this.students.filter((res) => {
        if (res.value === value) {
          this.form.enroll_code = res.enrollCode;
        }
      });
    },
  },

  methods: {
    convertNumberToWords,
    // queryString() {
    //     let json = this.pagination;
    //     return '?' +
    //         Object.keys(json).map(function (key) {
    //             if (![null, undefined].includes(json[key]))
    //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    //         }).join('&') + '&enrollCode=' + this.form.enroll_code;
    // },
    get(params) {
      // console.log(params);
      if (this.form.enroll_code) {
        let extraParams = "&enrollCode=" + this.form.enroll_code;

        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.loading = true;
        this.$rest
          .get("/api/report/statement" + query)
          .then(({ data }) => {
            let $this = this;
            $this.billSum = 0;
            $this.instantDiscountSum = 0;
            $this.totalSum = 0;
            $this.totalDueAdvAmount = 0;
            $this.advanceOpening = data.advance_opening;
            this.downloadUrl = data.download_url;
            data.data.map((item) => {
              $this.billSum += parseFloat(item.bill_amount);
              $this.totalSum += parseFloat(item.paid_amount);
              $this.instantDiscountSum += parseFloat(item.instant_discount);
            });

            $this.totalDueAdvAmount =
              $this.billSum -
              $this.totalSum -
              $this.instantDiscountSum -
              $this.advanceOpening;

            this.students = [
              {
                value: data.student.enroll_code,
                enrollCode: data.student.enroll_code,
                text:
                  data.student.name +
                  " (" +
                  data.student.grade +
                  "-" +
                  data.student.section +
                  ") - " +
                  data.student.roll,
              },
            ];

            this.form.name = data.student.enroll_code;
            this.form.items = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    print(item) {
      let url =
        this.printUrl +
        "?receipt=" +
        item.bill_no +
        "&enrollId=" +
        item.enroll_id;
      window.open(url);
      // console.log(this.printUrl);
      // console.log(item);
    },
  },
};
</script>
